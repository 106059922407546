import React from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"

import styles from "../utils/upload.module.scss"

const UploadErrorNotification = ({ fileName, message }) => (
  <div
    className={classNames(
      "m-1 notification has-text-danger has-text-left has-border-left-danger",
      styles[("cart", "errorCard")]
    )}
  >
    <article className={classNames("media")}>
      <figure className={classNames("media-left is-size-3")}>
        {" "}
        <FontAwesomeIcon icon={faExclamationCircle} />
      </figure>
      {fileName ? <span>&nbsp;</span> : ""}
      <div className="media-content is-size-7">
        {fileName && (
          <div
            className={classNames(
              "has-text-weight-bold subtitle",
              styles["uploadFileName"]
            )}
          >
            {" "}
            {fileName}
            <p className={classNames("help has-text-danger is-hidden-mobile")}>
              {message}
            </p>
            <p
              className={classNames(
                "help has-text-danger is-hidden-desktop is-hidden-tablet",
                styles["errorCard__fileMessage__mobile"]
              )}
            >
              {message}
            </p>
          </div>
        )}
        {!fileName && (
          <div className={classNames(" subtitle", styles["uploadFileName"])}>
            <p
              className={classNames(
                "has-text-danger is-hidden-mobile",
                styles["errorCard__message"]
              )}
            >
              {message}
            </p>
            <p
              className={classNames(
                "has-text-danger is-hidden-desktop is-hidden-tablet",
                styles["errorCard__message__mobile"]
              )}
            >
              {message}
            </p>
          </div>
        )}
      </div>
    </article>
  </div>
)

export default UploadErrorNotification
