import React from "react"
import classNames from "classnames"
import styles from "../utils/upload.module.scss"

const UploadDropzoneLabel = ({ label, onClick }) => {
  return (
    <div
      className={classNames(
        "has-background-primary mt-0 is-flex is-align-items-center is-justify-content-center",
        styles["dropzone__bottom"]
      )}
      onClick={onClick}
    >
      <p className="has-text-white is-size-6 has-text-centered has-text-weight-bold">
        {label || "Upload Document"}
      </p>
    </div>
  )
}

export default UploadDropzoneLabel
